import React from "react";
import "../css/footer.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaPhoneSquare, FaEnvelope, FaMapMarkedAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='top-footer-bar'>
        <div className='left-footer-bar'>
          <h3>EezyHealth</h3>
          <p>
            An online health care application.
          </p>

          <div className='footer-icons'>
            <FaFacebook className='icons' /> <FaXTwitter className='icons' />
            <FaInstagram className='icons' /> <FaLinkedin className='icons' />
          </div>
        </div>
        <div className='right-footer-bar'>
          <div>
            <h3>Contact</h3>
          </div>
          <div>
            <p>02013300308</p>
          </div>
          <div>
            <p>eezyhealth.com</p>
          </div>
          <div>
            <p>Lagos, Nigeria</p>
          </div>
        </div>
      </div>
      <div className='bottom-footer-bar'>
        <p>EeezyHealth 2024</p>
      </div>
    </div>
  );
};

export default Footer;
