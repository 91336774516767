import React from "react";
import "./App.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Testimonial from "./components/Testimonial";
import Download from "./components/Download";
import Footer from "./components/Footer";

function App() {
  return (
    <div className='main-div'>
      <Header />
      <Hero />
      <Features />
      <Testimonial />
      <Download />
      <Footer />
    </div>
  );
}

export default App;
