import React from "react";
import "../css/Header.css";
import logo from "../assets/static/images/eezy.png";
import { Link } from "react-scroll";

const headerItems = [
  { name: "Features", link: "features" },
  { name: "Testimonial", link: "testimonial" },
  { name: "Download", link: "download" },
];

const Header = () => {
  return (
    <div id='header'>
      <a href='/'>
        <img src={logo} alt='logo' />
      </a>
      <ul>
        {headerItems.map(({ name, link }, i) => (
          <li key={i}>
            <Link to={link} smooth={true} duration={500}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
      <a className='header-button' href='/'>
        Get the App
      </a>
    </div>
  );
};

export default Header;
