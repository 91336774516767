import React from "react";
import "../css/download.css";

import applelogo from "../assets/static/images/apple.png";
import googlelogo from "../assets/static/images/googleplay.png";

const Download = () => {
  return (
    <div id='download'>
      <div className='download-div'>
        <h1>Download EezyHealth App Now!</h1>
        <h2>
          EezyHealth will aid in driving the accessibility of quality and affordable specialist healthcare in
          Nigeria and Africa, especially for the underserved in rural and peri-urban communities. To provide top-notch
          quality healthcare, EezyHealth will contain a repository of verified information of medical
          practitioners and establishments, across the country.
        </h2>
        <div className='download-buttons-div'>
          <a href='/'>
            <img src={applelogo} alt='app' />
            <p className='download-text'>
              Download on the <span>App Store</span>
            </p>
          </a>
          <a href='/'>
            <img src={googlelogo} alt='app' />
            <p className='download-text'>
              GET IT ON <span>Google Play</span>
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Download;
