import React from "react";
import "../css/testimonial.css";

const testimonials = [
  {
    quote:
      "EezyHealth offers a much-needed digital transformation for healthcare. The features are intuitive, and the patient-doctor interaction is smooth.",
    author: "Mr. Pascal Ojinnaka",
  },
];

const Testimonial = () => {
  return (
    <div id='testimonial'>
      <h1>
        What are <span>People</span> Saying?
      </h1>
      <div className='testimonial-grid'>
        {testimonials.map(({ quote, author }, index) => (
          <div key={index} className='feature-card'>
            <h3 className='testimonial-title'>{author}</h3>
            <p className='testimonial-list'>{quote}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
