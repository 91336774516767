import React from "react";
import "../css/Hero.css";
import applelogo from "../assets/static/images/apple.png";
import googlelogo from "../assets/static/images/googleplay.png";
import phoneImg1 from "../assets/static/images/d3743f19e7e551c3a7f03b429588f641.png";
import phoneImg2 from "../assets/static/images/7b18cb34d74dbbff428fe0dfd1bd2974.png";
import phoneImg3 from "../assets/static/images/4f8aa62876db524daf8ebe5f8709700d.png";
import phoneImg4 from "../assets/static/images/37465e69f5c5863785bc87c2a7369542.png";

const Hero = () => {
  return (
    <div id='hero'>
      <h1>EezyHealth: An online health care application.</h1>
      <p className='hero-text'>
        Access affordable and quality healthcare anywhere leveraging mobile devices.
      </p>
      <div className='download-buttons'>
        <a href='/'>
          <img src={applelogo} alt='app' />
          <p className='download-text'>
            Download on the <span>App Store</span>
          </p>
        </a>
        <a href='/'>
          <img src={googlelogo} alt='app' />
          <p className='download-text'>
            GET IT ON <span>Google Play</span>
          </p>
        </a>
      </div>
      <div className='image-div'>
        <img src={phoneImg1} alt='' />
        <img src={phoneImg2} className='image-2' alt='' />
        {/* <img src={phoneImg4} alt='' /> */}
      </div>
      <div className='image-div2'>
        <img src={phoneImg1} alt='' />
      </div>
    </div>
  );
};

export default Hero;
