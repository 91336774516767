import React from "react";
import "../css/Features.css";

const features = [
  {
    title: "For Doctors",
    items: [
      "Onboarding & Registration.",
      "Appointment Slot Management.",
      "Patient History Access.",
      "Appointment Prescriptions & Management.",
      "Reports & Notifications.",
    ],
  },
  // {
  //   title: "For Admins",
  //   items: [
  //     "Manage all bookings and appointments.",
  //     "CRUD functionality for medical specializations.",
  //     "Document verification and upload management.",
  //   ],
  // },
  {
    title: "For Patients",
    items: [
      "Easy Onboarding & Profile Setup",
      "Appointment Booking with verified Doctors",
      "Appointment Management",
      "Post-Appointment Interaction & Reports",
      "In-App Chat and Video communication with Doctors",
      "Chat and video communication with doctors.",
    ],
  },
];
const Features = () => {
  return (
    <div id='features'>
      <h1>Features of this EezyHealth Applicaton</h1>
      <div className='features-grid'>
        {features.map((feature, index) => (
          <div key={index} className='feature-card'>
            <h3 className='feature-title'>{feature.title}</h3>
            <ul className='feature-list'>
              {feature.items.map((item, i) => (
                <li key={i} className='feature-item'>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
